import { useApesStorageApi } from "@/api/useApesStorageApi";
import { signMessage } from "@/helpers/sign-message";
import { Config } from "@/config";
import { useWeb3Store } from "@/store/web3";
import { useToast } from "@/composables/useToast";
import { captureError } from "@/helpers/captureError";
export const traitMarketplaceBuy = async ({
  offerId,
  amount,
  id,
  isUtility,
  isSelling,
}) => {
  try {
    const message = !isSelling ? "I'm buying an item" : "I'm selling an item"
    const signature = await signMessage(
      message
    );
    if (!signature) return;
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/trading/fulfill",
      method: "POST",
      data: {
        wallet: useWeb3Store().collectionWallet, // of the wallet buying
        offerId, // offer id from DB
        amount, // how many is buying (should be less or equal as available amount)
        signature, //  also not checking for now
        buyOrder: isSelling, // true if executing buy order (selling the trait), false if executing sell order (buying the trait)
        network: Config.network.name,
      },
    });
    useToast().success({
      title: `${isUtility ? "Utility" : "Trait"} #${id} ${
        amount > 1 ? `x${amount}` : ""
      } was successfully ${isSelling ? "sold" : "bought"}!`,
    });
    return res;
  } catch (err) {
    captureError(err);
  }
};

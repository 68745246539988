<template>
  <div class="formulas-preview">
    <template v-if="hasSelectedFormula">
      <FormulasPreviewPrize />

      <div class="formulas-preview-action">
        <a
          class="formulas-preview-action__forge"
          :class="{ disabled: forgeDisabled || isLoading }"
          href="javascript:void(0)"
          @click.prevent="buildTrait"
        >
          {{
            isGodMinting
              ? "Summon God Ape"
              : isApeMinting
              ? "Claim Ape"
              : "Forge Trait"
          }}
          <img
            v-if="isLoading"
            class="loader"
            src="@/assets/img/spinner-clear.svg"
            width="20"
          />
        </a>
      </div>
      <div class="formulas-preview-error" v-if="forgeDisabled">
        <div class="formulas-preview-error-title" v-if="isSoldOut">
          SOLD OUT
        </div>
        <div class="formulas-preview-error-title" v-else-if="isComingSoon">
          COMING SOON
        </div>
        <template v-else>
          <div class="formulas-preview-error-title">Forging is blocked</div>

          <div class="formulas-preview-error-text">
            Some necessary ingredients are not available in the wallet
          </div>
        </template>
      </div>
      <FormulasIngredients :key="activeFormulaId" />
      <FormulasPreviewDisclaimer v-if="activeFormulaId === 'GC7'" />
    </template>
    <div class="formulas-preview-empty" v-else>Select Formula</div>
  </div>
</template>
<script setup>
import FormulasPreviewPrize from "./FormulasPreviewPrize/FormulasPreviewPrize.vue";
import FormulasIngredients from "../FormulasIngredients/FormulasIngredients.vue";
import { computed, ref } from "vue-demi";
import { useFormulasStore } from "@/store/formulas/formulas";
import { forgeTrait } from "@/functions/formulas/forge-trait/forge-trait";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import FormulasPreviewDisclaimer from "./FormulasPreviewDisclaimer.vue";
const formulasStore = useFormulasStore();
const isSoldOut = computed(() => formulasStore.activeFormula.is_minted_out);
const isComingSoon = computed(() => formulasStore.activeFormula.comingSoon);
const isGodMinting = computed(
  () => formulasStore.activeFormula.trait_out.type === "god"
);
const isApeMinting = computed(
  () => formulasStore.activeFormula.trait_out.type === "ape_from_ticket"
);
const forgeDisabled = computed(
  () =>
    isSoldOut.value ||
    isComingSoon.value ||
    formulasStore.ingredientsSatisfyMap.includes(false)
);
const hasSelectedFormula = computed(() => !!formulasStore.activeFormulaId);
const activeFormulaId = computed(() => formulasStore.activeFormulaId);
const isLoading = ref(false);
const buildTrait = async () => {
  const traits = [];
  isLoading.value = true;
  formulasStore.formulaIngredients.forEach((ingredient, idx) => {
    if (
      [FormulasIngredientType.TRAIT, FormulasIngredientType.UTILITY].includes(
        ingredient.type
      )
    ) {
      traits.push({ type: ingredient.type, item: ingredient.item });
    }
    if (formulasStore.isSelectable(ingredient.type)) {
      traits.push({
        type: ingredient.type,
        item: formulasStore.selectedTraits[idx],
      });
    }
  });

  await forgeTrait(
    formulasStore.activeFormula.combination_id,
    traits,
    formulasStore.activeFormula.blocks_required
  );
  isLoading.value = false;

  formulasStore.selectFormula(null);
};
</script>
<style lang="scss">
.formulas-preview {
  min-width: 720px;
  &-action {
    &__forge {
      background-color: var(--purple);
      color: #fff;
      border-radius: 5px;
      text-decoration: none;
      padding: 10px 30px;
      width: 80%;
      text-align: center;
      text-transform: uppercase;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      margin: 0 auto;
      img {
        margin-left: 4px;
      }
      &:hover {
        filter: brightness(0.8);
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
  &-error {
    text-align: center;
    margin-top: 10px;
    &-title {
      font-size: 18px;
      color: var(--red);
    }
    &-text {
      font-size: 16px;
      opacity: 0.8;
    }
  }
  &-empty {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 20px;
    opacity: 0.5;
  }
  @media screen and (max-width: 1200px) {
    min-width: 0;
  }
  @media screen and (max-width: 576px) {
    &-action {
      &__forge {
        width: 100%;
      }
    }
  }
}
</style>

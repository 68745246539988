<template>
    <div class="formulas-gac-phase">
        <div class="formulas-gac-phase__title">
            <span>Phase {{ phase.phaseNumber }}</span>
        </div>
        <div
            class="formulas-gac-phase-team formulas-gac-phase-team-ava"
            v-if="phase.ava && phase.ava.items.length"
        >
            <div class="formulas-gac-phase-team__title">
                Team Ava
                <span class="formulas-gac-phase-team__info" v-if="userTeam === 'ava'">
                    <span
                        class="formulas-gac-phase-team__completed"
                        v-if="userPhase > (phase.phaseNumber)"
                    >
                        <span>
                            <ICheckmark width="16" />
                        </span>
                        Completed
                    </span>
                    <span
                        v-else
                        class="formulas-gac-phase-team__progress"
                    >({{ phase.ava.completed }}/{{ phase.ava.items.length }} completed)</span>
                </span>
            </div>
            <div class="formulas-items formulas-gac-items">
                <div
                    class="formulas-items__item formulas-gac-phase-team__item"
                    v-for="item in phase.ava.items"
                    :key="item"
                >
                    <FormulasFormulaCard :item="item" @click.prevent="$emit('select', item)" />
                </div>
            </div>
        </div>
        <div
            class="formulas-gac-phase-team formulas-gac-phase-team-vyl"
            v-if="phase.vyl && phase.vyl.items.length"
        >
            <div class="formulas-gac-phase-team__title">
                Team Vyl
                <span class="formulas-gac-phase-team__info" v-if="userTeam === 'vyl'">
                    <span
                        class="formulas-gac-phase-team__completed"
                        v-if="userPhase > (phase.phaseNumber)"
                    >
                        <span>
                            <ICheckmark width="18" />
                        </span>
                        Completed
                    </span>
                    <span
                        v-else
                        class="formulas-gac-phase-team__progress"
                    >({{ phase.vyl.completed }}/{{ phase.vyl.items.length }} completed)</span>
                </span>
            </div>
            <div class="formulas-items formulas-gac-items">
                <div
                    class="formulas-items__item formulas-gac-phase-team__item"
                    v-for="item in phase.vyl.items"
                    :key="item"
                >
                    <FormulasFormulaCard :item="item" @click.prevent="$emit('select', item)" />
                </div>
            </div>
        </div>
        <div
            class="formulas-gac-phase-team formulas-gac-phase-team-all"
            v-if="phase.all && phase.all.items.length"
        >
            <div class="formulas-gac-phase-team__title">
                Both Teams
                <span class="formulas-gac-phase-team__info">
                    <span
                        class="formulas-gac-phase-team__completed"
                        v-if="userPhase > (phase.phaseNumber)"
                    >
                        <span>
                            <ICheckmark width="18" />
                        </span>
                        Completed
                    </span>
                    <span
                        v-else
                        class="formulas-gac-phase-team__progress"
                    >({{ phase.all.completed }}/{{ phase.all.itemsToComplete }} completed)</span>
                </span>
            </div>
            <div class="formulas-items formulas-gac-items">
                <div
                    class="formulas-items__item formulas-gac-phase-team__item"
                    v-for="item in phase.all.items"
                    :key="item"
                >
                    <FormulasFormulaCard :item="item" @click.prevent="$emit('select', item)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { computed } from "vue-demi";
import FormulasFormulaCard from "../FormulasFormulaCard/FormulasFormulaCard.vue";
import ICheckmark from "@/assets/icons/checkmark.svg?inline"

defineProps({ phase: Object })
defineEmits(['select'])

const userTeam = computed(() => useGodApeQuestStore().team)
const userPhase = computed(() => useGodApeQuestStore().currentPhase)
</script>
<style lang="scss"></style>
export const FormulasIngredientType = {
  METH: "METH",
  FORGE_BLOCK: "FORGE_BLOCK",
  TRAIT: "trait",
  TRAIT_CATEGORY: "category",
  RARITY: "rarity",
  FORMULA_TRAIT: "formula_trait",
  TRAIT_ANY: "any",
  UTILITY: "utility",
  OG_BULL: "og_bull",
  TAGS: "tags",
  METH_MACHINE: "meth_machine",
  RESURRECTION: "resurrection",
  BREEDING_REPLENISHMENT: "breeding_replenishment",
  APE: "ape",
  TEEN: "teen",
};

<template>
  <div class="formulas-ingredient-og-bull-item">
    <FormulasIngredientCard :item="utilItem" v-bind="$attrs" />
  </div>
</template>
<script setup>
import { computed } from "vue-demi";
import FormulasIngredientCard from "../FormulasIngredientCard/FormulasIngredientCard.vue";
const props = defineProps({
  item: Object,
});
const utilItem = computed(() => ({
  ...props.item,
  // onChainCount: props.item.onChainCount ? 0 : 1,
  // offChainCount: props.item.offChainCount ? 1 : 0,
}));
</script>
<style lang="scss"></style>

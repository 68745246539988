<template>
  <AModalContent class="modal-confirm">
    <div
      class="modal-confirm-title"
      v-html="title"
    />
    <div
      class="modal-confirm-text"
      v-html="text"
    />
    <div class="modal-confirm-actions">
      <a
        v-if="showCancel"
        class="modal-confirm-action modal-confirm-action-cancel"
        href="javascript:void(0)"
        @click.prevent="cancel"
      >{{ cancelText }}</a>
      <a
        class="modal-confirm-action modal-confirm-action-confirm"
        href="javascript:void(0)"
        @click.prevent="confirm"
      >{{ confirmText }}</a>
    </div>
  </AModalContent>
</template>
<script>
import { defineComponent } from "vue-demi";
import AModalContent from "../Modal/AModalContent.vue";

export default defineComponent({
    components: { AModalContent },
    props: {
        title: String,
        text: String,
        onConfirm: Function,
        onCancel: Function,
        confirmText: {
            type: String,
            default: "Confirm"
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        showCancel: {
            type: Boolean,
            default: true
        }

    },
    setup(props, { emit }) {
        const confirm = () => {
            props.onConfirm && props.onConfirm()
            emit('close')

        }
        const cancel = () => {
            props.onCancel && props.onCancel()
            emit('close')
        }
        return {
            confirm, cancel
        }
    }
})
</script>
<style lang="scss">
.modal-confirm {
    padding: 20px 15px;
    width: 400px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--gray);
    border: 1px solid var(--border);
    border-radius: 10px;
    &-title {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    &-text {
        width: 100%;
        opacity: 0.8;
        a {
            color: var(--primary);
        }
    }
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 15px;
    }
    &-action {
        flex: 1;
        padding: 10px 15px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        transition: 0.2s;
        font-weight: bold;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            filter: brightness(0.8);
        }
        &-confirm {
            background-color: var(--green);
        }
        &-cancel {
            background-color: var(--red);
            margin-right: 10px;
        }
    }
}
</style>
<template>
    <div class="formulas-quest-info" :class="team">
        <IInfo class="formulas-quest-info-icon" width="16" />
        <div class="formulas-quest-info-text">
            Forge all traits from team
            <span>{{ teamName }}</span> to complete your Phase
        </div>
    </div>
</template>
<script setup>import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { computed } from "vue-demi";
import IInfo from "@/assets/img/info.svg?inline"
const godApeQuestStore = useGodApeQuestStore()
const team = computed(() => godApeQuestStore.team)
const teamName = computed(() => godApeQuestStore.team[0].toUpperCase() + godApeQuestStore.team.slice(1))
</script>
<style lang="scss">
.formulas-quest-info {
    display: flex;
    align-items: center;
    margin-top: 14px;
    opacity: 1;
    &-icon {
        margin-right: 5px;
    }
    &-text {
        span {
        }
    }
    &.ava & {
        &-text {
            span {
                color: var(--ape-quest-ava);
            }
        }
    }
    &.vyl & {
        &-text {
            span {
                color: var(--ape-quest-vyl);
            }
        }
    }
}
</style>
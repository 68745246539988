<template>
    <div class="formulas-tabs">
        <a
            href="javascript:void(0)"
            class="formulas-tab"
            @click.prevent="selectTab(tab.value)"
            :class="{ active: activeTab === tab.value }"
            v-for="tab in items"
            :key="tab.value"
        >
            <img :src="tab.image" alt v-if="tab.image" width="18">
            {{ tab.name }}
        </a>
    </div>
</template>
<script setup>
const props = defineProps({
    activeTab: String,
    items: {
        type: Array,
        default: () => ([])
    }
})
const emit = defineEmits(['update:activeTab'])
const selectTab = (tab) => {
    emit('update:activeTab', tab)
}
</script>
<style lang="scss">
.formulas-tabs {
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media screen and (max-width: 450px) {
        flex-direction: column;
    }
}
.formulas-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    transition: 0.2s;
    text-decoration: none;
    color: #fff;
    border-radius: 25px;
    margin-right: 5px;
    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: var(--gray-light);
    }
    &.active {
        background-color: var(--purple);
    }
    img {
        margin-right: 5px;
    }
    @media screen and (max-width: 450px) {
        margin-right: 0;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
import { moveTraitsOffChain } from "../moveTraitsOffChain";
import { moveUtilitiesOffChain } from "../moveUtilitiesOffChain";
import { forgeBlocksOffChain } from "./forge-blocks-offchain";
import { useConfirmModal } from "@/composables/useConfirmModal";

// [{id, count}]
export const forgeBlocks = async (items) => {
  const onChainTraits = items
    .filter((item) => item.rarity !== "utility" && item.count > item.offChain)
    .map((item) => ({ ...item, count: item.count - item.offChain }));

  if (onChainTraits.length) {
    const userAgrees = await useConfirmModal({
      title: "Need to move Traits off-chain",
      text: `You need to move ${onChainTraits.length} Traits off-chain in order to forge blocks.
            <br>After the process is complete, you will be able to forge blocks as usual`,
    });
    if (!userAgrees) return false;
    const done = await moveTraitsOffChain(onChainTraits);
    if (!done) return;
  }

  const onChainUtilities = items
    .filter((item) => item.rarity === "utility" && item.count > item.offChain)
    .map((item) => ({ ...item, amount: item.count - item.offChain }));

  if (onChainUtilities.length) {
    const userAgrees = await useConfirmModal({
      title: "Need to move Utilities off-chain",
      text: `You need to move ${onChainUtilities.length} Utilities off-chain in order to forge blocks.
            <br>After the process is complete, you will be able to forge blocks as usual`,
    });
    if (!userAgrees) return false;
    const done = await moveUtilitiesOffChain(onChainUtilities);
    if (!done) return;
  }


  return await forgeBlocksOffChain(items);
};

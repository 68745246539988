<template>
    <FormulasIngredientSelectable
        :items="items"
        :idx="idx"
        criteriaName="Bull with 3 breedings"
        :tooltipText="{content: 'Any Bull from OG Mint with 3 breedings can be used. <br> Both bulls should have same Guild. <br> After using formula your Bull will stay in your wallet.', html: true, distance: 10}"
        selectText="Select OG Bull <br> <span>(Only Breedings will be Exhausted)</span>"
        selectEmptyText="You don't have OG Bulls matching this criteria"
        v-model:selectedItem="formulasStore.selectedTraits[idx]"
    >
        <template #selected-item="{ item }">
            <FormulasIngredientOGBullItem :isSatisfy="isSatisfy" :isSelectMode="false" :item="item"></FormulasIngredientOGBullItem>
            <!-- <ApeTrait class="formulas-ingredient-selectable__preview" :item="item">
                <template #details>
                    <button class="ape-trait__button formulas-ingredient-selectable__button">Change</button>
                </template>
            </ApeTrait>-->
        </template>
        <template #select-item="itemInfo">
            <!-- <FormulasIngredientUniversalTrait v-bind="itemInfo" /> -->
            <FormulasIngredientOGBullItem :isSelectMode="true" v-bind="itemInfo" />
        </template>
    </FormulasIngredientSelectable>
</template>
<script setup>
import { computed } from "vue-demi";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import FormulasIngredientOGBullItem from "./FormulasIngredientOGBullItem.vue";
import FormulasIngredientSelectable from "../FormulasIngredientSelectable/FormulasIngredientSelectable.vue";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
const props = defineProps({
    item: {
        type: Object
    },
    idx: Number,
    isSatisfy: Boolean
})
const formulasStore = useFormulasStore()
const selectedTrait = computed(() => {
    return formulasStore.selectedTraits[props.idx]
})


const items = computed(() => {
    console.log(props.item)
    const selectedTraits = formulasStore.selectedTraits
    const ogBullIngredientsIndexes = formulasStore.formulaIngredients.filter(ingredient => ingredient.type === FormulasIngredientType.OG_BULL).map(item => item.idx)

    const selectedBulls = ogBullIngredientsIndexes.filter(ingredientIdx => {
        return !!selectedTraits[ingredientIdx] && ingredientIdx !== props.idx
    }).map(ingredientIdx => selectedTraits[ingredientIdx])
    const availableBulls = useWalletCollectionStore().genesis.filter(bull => {
        return bull.breedingsLeft >= props.item.breedings && selectedBulls.findIndex(b => b.id === bull.id) < 0
    })
    if (selectedBulls.length === 1) {
        return availableBulls.filter(bull => bull.guild === selectedBulls[0].guild)
    }
    return availableBulls

})


</script>
<style lang="scss">
</style>
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import ConfirmModalVue from "@/components/Modals/ConfirmModal.vue";
import { useConfirmModal } from "@/composables/useConfirmModal";
import { useContract } from "@/composables/useContract";
import { useModal } from "@/composables/useModal";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import router from "@/router";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { useWeb3Store } from "@/store/web3";
import { useWakeLock } from "@vueuse/core";

const abi = [
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "apeId", type: "uint256" },
      { internalType: "uint256", name: "element", type: "uint256" },
      { internalType: "uint256", name: "compass", type: "uint256" },
      { internalType: "bytes", name: "signature", type: "bytes" },
    ],
    name: "mintGodApes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
// TODO add mainnet address
const contractAddress =
  Config.network.name === "sepolia"
    ? "0x862C6DD3b62fb2A4d9411a8F9395C6d80dA1840E"
    : "0x4Ed88b09Eb02504CD5df19C6786a7d5C6535d0d6";
const useMintGodApeContract = useContract(abi, contractAddress);
export const mintGodApe = async ({ element, compass, signature, apeId }) => {
  try {
    const approve = await useConfirmModal({
      title: "Mint God Ape",
      text: "Sign transaction to mint your God Ape",
      confirmText: "Mint",
    });
    if (!approve) return;
    const recipient = useWeb3Store().walletAddress;

    const res = await useToastTransaction(
      useMintGodApeContract(true).methods.mintGodApes(
        recipient,
        apeId,
        element,
        compass,
        signature
      ),
      {
        text: `Minting God Ape`,
      }
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
    router.push({ name: "GodApeQuest" });
    let apeMetadata = null;
    for (let i = 0; i < 5; i++) {
      // retry
      apeMetadata = await useApesStorageApi().exec({
        url: `/metadata/ape/${apeId + 10000}`,
      });
      if (apeMetadata) break;
    }
    useModal().open({
      name: ModalName.GodApeGeneratedModal,
      props: {
        team: compass === 0 ? "ava" : "vyl",
        metadata: {
          tx: res.transactionHash,
          ...apeMetadata,
        },
      },
    });

    useGodApeQuestStore().init(true);
  } catch (err) {
    captureError(err);
  }
};

const mintApeAbi = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokenAddresses",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "timeOut",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "withdrawNFTWithSignature",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
// TODO add mainnet address
const mintApeContractAddress =
  Config.network.name === "sepolia"
    ? "0x8CC20D4Ee54AE095082B57d9d223B63CAdaD1523"
    : "0x4C60256bD2907916505079a41CCbC4979D4019D2";
const useMintApeContract = useContract(mintApeAbi, mintApeContractAddress);

export const mintApeFromTicket = async ({
  tokenAddresses,
  tokenIds,
  amounts,
  timeOut,
  signature,
}) => {
  try {
    const approve = await useConfirmModal({
      title: "Claim Your Ape",
      text: "Sign transaction to claim your Ape",
      confirmText: "Claim",
    });
    if (!approve) return;
    const recipient = useWeb3Store().walletAddress;

    const res = await useToastTransaction(
      useMintApeContract(true).methods.withdrawNFTWithSignature(
        tokenAddresses,
        tokenIds,
        amounts,
        recipient,
        timeOut,
        signature
      ),
      {
        text: `Claiming Ape`,
      }
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
    // router.push({ name: "GodApeQuest" });
    let apeMetadata = null;
    for (let i = 0; i < 5; i++) {
      // retry
      apeMetadata = await useApesStorageApi().exec({
        url: `/metadata/ape/${tokenIds[0]}`,
      });
      if (apeMetadata) break;
    }
    useModal().open({
      name: ModalName.ClaimApeModal,
      props: {
        metadata: {
          tx: res.transactionHash,
          ...apeMetadata,
        },
      },
    });

    // useGodApeQuestStore().init(true);
  } catch (err) {
    captureError(err);
  }
};

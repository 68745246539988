import { useConfirmModal } from "@/composables/useConfirmModal";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import {  useAssetsInteractionsContract } from "@/web3/contracts";
import { approveTeens } from "./approveTeens";

export const burnTeens = async function (teens) {
  try {
    const teensToBurn = teens.filter((teen) => !teen.isBurned);

    if (teensToBurn.length === 0) return;

    const teensApproved = await approveTeens(
      "burn Teens for Phase 6"
    );
    if (!teensApproved) return;
    const contract = useAssetsInteractionsContract(true);

    const ids = teens.map((teen) => teen.id);

    const userAgrees = await useConfirmModal({
      title: "Burning Teens",
      text: `You are about to burn ${ids.length} Teen${ids.length > 1 ? 's' : ''}
              <br>This is required in order to proceed with the phase
              <br>After transaction is confirmed, the process will continue as usual`,
    });
    
    if (!userAgrees) return false;

    const burnResponse = await useToastTransaction(
      contract.methods.burnTeens(ids),
      {
        text: "Burning Teens",
      }
    );
    useWalletCollectionStore().fetchCollection(true);
    useWeb3Store().update();
    return burnResponse;
  } catch (err) {
    captureError(err);
  }
};

<template>
    <div
        class="formulas-preview-disclaimer"
    >This Formula requires 3 breedings from both bulls (total 6) of the same Guild and not bulls themselves</div>
</template>
<script setup>

</script>
<style lang="scss">
.formulas-preview-disclaimer {
    margin-top: 18px;
    text-align: center;
    color: var(--accent);
}
</style>
import { useConfirmModal } from "@/composables/useConfirmModal";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { isApprovedForTeens } from "@/helpers/utils";
import {
  useTeensContract,
  useAssetsInteractionsContract
} from "@/web3/contracts";

const { useWeb3Store } = require("@/store/web3");

export const approveTeens = async function (purpose) {
  const walletAddress = useWeb3Store().walletAddress;
  const isApproved = await isApprovedForTeens(walletAddress);
  if (isApproved) return true;
  const userAgrees = await useConfirmModal({
    title: "Contract needs your approval",
    text: `The Contract requires your approval to use your Teen Bull tokens
            <br>This is required in order to ${purpose}
            <br>After you approve the Contract, the claim process will continue as usual`,
  });
  if (!userAgrees) return false;

  const contract = useTeensContract(true);

  const approveForAllResponse = await useToastTransaction(
    contract.methods.setApprovalForAll(useAssetsInteractionsContract().options.address, true),
    {
      text: "Approving Teen Bulls Contract",
      successText: "You have successfully approved the Teen Bulls Contract",
    }
  );
  return approveForAllResponse;
};

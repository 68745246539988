<template>
    <div class="formulas-ingredient-selectable">
        <VDropdown
            theme="formulas-ingredient-selectable"
            :triggers="['click']"
            auto-hide
            placement="bottom"
            :distance="24"
        >
            <template #popper>
                <FormulasIngredientSelectableSelect
                    :emptyText="selectEmptyText"
                    :items="items"
                    @select="$emit('update:selectedItem', $event)"
                >
                    <template #default="itemInfo">
                        <slot name="select-item" v-bind="itemInfo" />
                    </template>
                </FormulasIngredientSelectableSelect>
            </template>
            <div>
                <div class="formulas-ingredient-selectable__empty" v-if="!isSelected">
                    <IPlus width="30" />
                    <div class="formulas-ingredient-selectable__empty-title" v-html="selectText"></div>
                </div>
                <slot v-else name="selected-item" :item="selectedItem"></slot>
                <!-- <ApeTrait
                    
                    class="formulas-ingredient-selectable__preview"
                    :item="selectedTrait"
                >
                    <template #details>
                        <button
                            class="ape-trait__button formulas-ingredient-selectable__button"
                        >Change</button>
                    </template>
                </ApeTrait>-->
            </div>
        </VDropdown>

        <div
            class="formulas-ingredient-selectable-name"
            v-tooltip="typeof tooltipText === 'string' ? { content: tooltipText, distance: 10 } : tooltipText"
        >
            {{ criteriaName }}
            <IInfo width="12" v-if="!!tooltipText" />
        </div>
    </div>
</template>
<script setup>
import { computed } from "vue-demi";
import IPlus from "@/assets/icons/plus-stroke.svg?inline"
import IInfo from "@/assets/img/info.svg?inline"
import { useFormulasStore } from "@/store/formulas/formulas";
import FormulasIngredientSelectableSelect from "./FormulasIngredientSelectableSelect.vue";
import ApeTrait from "@/components/ApeTrait/ApeTrait.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
import { ACHIEVEMENT_FLAG_ID } from "@/helpers/achievement-flag";
const props = defineProps({
    items: Array,
    selectedItem: Object,
    criteriaName: String,
    tooltipText: [String, Object],
    selectText: String,
    selectEmptyText: String
})
const formulasStore = useFormulasStore()
const isSelected = computed(() => {
    return !!props.selectedItem
})

</script>
<style lang="scss">
.formulas-ingredient-selectable {
    padding-bottom: 15px;
    &__empty {
        min-height: 255px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        svg {
            opacity: 0.5;
        }
        &-title {
            text-transform: uppercase;
            opacity: 0.5;
            font-size: 14px;
            margin-top: 12px;
            text-align:center;
            span {
                font-size: 0.9em;
            }
        }
    }
    &__preview {
        cursor: pointer;
        background: #000;
    }
    &__name {
    }

    &__button {
        border: 1px solid var(--primary);
        color: var(--primary);
        background: transparent;
        text-transform: uppercase;
        margin-top: 3px;
        cursor: pointer;
        width: 100%;
    }

    &-name {
        background: rgba(255, 255, 255, 0.3);
        text-align: center;
        padding: 2px;
        position: absolute;
        font-size: 14px;
        text-transform: uppercase;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        svg {
            margin-left: 2px;
            opacity: 0.5;
            transform: translateY(1px);
        }
    }
}
.v-popper--theme-formulas-ingredient-selectable .v-popper__arrow-outer {
    border-color: rgba(255, 255, 255, 0.3);
}
.v-popper--theme-formulas-ingredient-selectable .v-popper__inner {
    font-family: var(--font-oxanium);
    font-size: 14px;
    background-color: var(--gray-light);
    border-radius: 5px;
    padding: 0;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
</style>
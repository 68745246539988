<template>
    <RouterLink :to="{ name: 'GodApeQuest' }" class="formulas-start-quest-button">Start Quest</RouterLink>
</template>
<script setup>


</script>
<style lang="scss">
.formulas-start-quest-button {
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--ape-quest),
        0 0 0.8rem var(--ape-quest);
    color: #fff;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 5px;
    text-transform: uppercase;
    transition: .3s;
    margin: 0 auto;
    margin-top: 30px;

    &:hover {
        background: var(--ape-quest);
        box-shadow: none;
    }
}
</style>
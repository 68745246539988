<template>
    <div class="formulas-top-actions">
        <div class="formulas-top-actions-back button-back" @click.prevent="$emit('back')">
            <IArrowLeft width="32" />
            <span>Back</span>
        </div>
        <div
            class="formulas-top-action formulas-top-action-forge-balance"
            @click.prevent="$emit('burn')"
            v-tooltip="{ content: 'Burn traits to get forge blocks', distance: 10 }"
        >
            <IAnvil width="20" class="formulas-top-action-forge-balance-icon" />Forge Blocks
            <span>{{ forgeBlocksBalance }}</span>
            <IPlus width="16" class="formulas-top-action-forge-balance-add" />
        </div>

        <RouterLink :to="{ name: 'LootBox' }" class="formulas-top-action">
            <ILootBox width="20" />Lootbox
        </RouterLink>
        <TraitShopButton />
    </div>
</template>
<script setup>
import ILootBox from "@/assets/icons/lootbox.svg?inline"
import IArrowLeft from "@/assets/icons/arrow-left-long.svg?inline"
import { useRouter } from "vue-router"
import { computed } from "vue-demi"
import { useTraitShopCartStore } from "@/store/trait-shop/trait-shop-cart"
import TraitShopButton from "../TraitShop/TraitShopButton.vue"
import IAnvil from "@/assets/icons/anvil.svg?inline"
import IPlus from "@/assets/icons/plus.svg?inline"
import { useWalletCollectionStore } from "@/store/wallet-collection"
const forgeBlocksBalance = computed(() => useWalletCollectionStore().forgeBlocksBalance.all)
</script>
<style lang="scss">
.formulas-top-actions {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &-back {
        margin-right: auto;
    }
    @media screen and (max-width: 750px) {
        flex-direction: column;
        align-items: flex-start;
        &-back {
            margin-bottom: 10px;
        }
    }
}
.formulas-top-action {
    box-sizing: border-box;
    padding: 10px 18px;
    margin-right: 10px;
    border: 1px solid var(--border);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
    position: relative;
    &-forge-balance {
        cursor: pointer;

        &-add {
            margin-left: 3px;
            margin-right: 0;
            margin-top: -1px;
        }

        span {
            color: var(--primary);
            margin-left: 5px;
        }
        &-icon {
            margin-top: -5px;
        }
    }
    svg,
    img {
        margin-right: 5px;
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background-color: var(--gray-light);
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
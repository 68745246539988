import { moveTraitsOffChain } from "@/functions/moveTraitsOffChain";
import { moveUtilitiesOffChain } from "@/functions/moveUtilitiesOffChain";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { forgeTraitOffchain } from "./forge-trait-offchain";
import { useConfirmModal } from "@/composables/useConfirmModal";

export const forgeTrait = async (
  combinationId,
  ingredients,
  forgeBlocks = 0
) => {
  const formulasStore = useFormulasStore();
  const traits = ingredients
    .filter((item) => formulasStore.isTrait(item.type))
    .map((ingredient) => ingredient.item);
  const items = traits.reduce((arr, trait) => {
    const walletTrait = useWalletCollectionStore().apeTraits.find(
      (t) => t.id == trait.id
    );
    const count = traits
      .filter((t) => t.id == trait.id)
      .reduce((sum, item) => sum + (item.count || 1), 0);
    arr.push({ ...walletTrait, count });
    return arr;
  }, []);
  const onChainTraits = items
    .filter((item) => item.count > item.offChain)
    .map((item) => ({ ...item, count: item.count - item.offChain }));
  if (onChainTraits.length) {
    const userAgrees = await useConfirmModal({
      title: "Moving Traits off-chain",
      text: `You are about to move ${onChainTraits.length} Traits off-chain
              <br>This is required in order to proceed with the formula
              <br>After transaction is confirmed, the process will continue as usual`,
    });

    if (!userAgrees) return false;

    const done = await moveTraitsOffChain(onChainTraits);
    if (!done) return;
  }

  // moving forge blocks off-chain
  const forgeBlocksOffChainCount =
    useWalletCollectionStore().forgeBlocksBalance.offChain;
  const utilsToMove = [];
  if (forgeBlocks > forgeBlocksOffChainCount) {
    utilsToMove.push({ id: 3, amount: forgeBlocks - forgeBlocksOffChainCount });
  }

  // move utilities off-chain if needed
  const ingredientsUtils = ingredients.filter(
    (item) =>
      (item.type == "utility" ||
        item.type == "resurrection" ||
        item.type == "meth_machine" ||
        item.type == "breeding_replenishment") &&
      item.item.id != 3
  );

  // get utilities from wallet
  const walletUtilities = useWalletCollectionStore().utilities;

  // check if user has enough offChainCount for each utility
  ingredientsUtils.forEach((ingredient) => {
    const utility = ingredient.item;
    // const count = ingredient.count || 1;
    const count = ingredientsUtils.filter(
      (i) => i.item.id == utility.id
    ).length;
    const walletUtility = walletUtilities.find((u) => u.id == utility.id);

    if (
      walletUtility.offChainCount < count &&
      !utilsToMove.find((u) => u.id == utility.id)
    ) {
      utilsToMove.push({
        id: utility.id,
        amount: count - walletUtility.offChainCount,
      });
    }
  });

  // move utils off-chain
  ingredients
    .filter((item) => formulasStore.isUtility(item.type))
    .forEach((ingredient) => {
      const utility = ingredient.item;
      const walletUtilities = useWalletCollectionStore().utilities.filter(
        (u) => u.id == utility.id
      );
      if (
        walletUtilities.findIndex((util) => util.offChainCount) < 0 &&
        !utilsToMove.find((u) => u.id == utility.id)
      ) {
        utilsToMove.push({ id: utility.id, amount: 1 });
      }
    });
  if (utilsToMove.length) {
    const userAgrees = await useConfirmModal({
      title: "Moving Utilities off-chain",
      text: `You are about to move ${utilsToMove.length} Utilities off-chain
              <br>This is required in order to proceed with the phase
              <br>After transaction is confirmed, the process will continue as usual`,
    });

    if (!userAgrees) return false;

    const done = await moveUtilitiesOffChain(utilsToMove);
    if (!done) return;
  }

  return await forgeTraitOffchain(combinationId, ingredients);
};

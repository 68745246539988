<template>
    <div class="formulas-trait" :class="{ [`rarity-${rarity}`]: true, selected: isSelected }">
        <div class="formulas-trait__category">{{ item.categoryName }}</div>

        <div class="formulas-trait__counts">
            <div
                v-if="item.offChain >= 1"
                v-tooltip="'You have ' + item.offChain + ' off-chain trait' + (item.offChain > 1 ? 's' : '')"
                class="formulas-trait__count formulas-trait__count-offchain"
            >
                <IOffChain width="14" />
                {{ item.offChain }}
            </div>
            <div
                v-if="item.balance >= 1"
                v-tooltip="'You have ' + item.balance + ' on-chain trait' + (item.balance > 1 ? 's' : '')"
                class="formulas-trait__count formulas-trait__count-onchain"
            >
                <IWallet width="14" />
                {{ item.balance }}
            </div>
        </div>

        <div class="formulas-trait__image" @click.prevent="toggleSelect">
            <img
                width="512"
                height="512"
                :src="resizeImageCF(previewImage, 512)"
                alt="preview"
                loading="lazy"
            >
            <div v-if="isSelected" class="formulas-trait__selected">
                <div class="formulas-trait__selected-icon">
                    <ICheckmark width="24" />
                </div>
            </div>
        </div>
        <div class="formulas-trait__info">
            <div class="formulas-trait__name">{{ item.name }}</div>
            <div class="formulas-trait__rank" v-if=" item.rank">RANK {{ item.rank }}</div>
        </div>
        <div v-if="isSelectionMode" class="formulas-trait-counter">
            <ACounter v-model:value="count" :max="totalBalance" />
        </div>
        <a
            class="formulas-trait__button"
            href="#"
            @click.prevent="toggleSelect"
        >{{ isSelected ? 'De-select Trait' : 'Select Trait' }}</a>
        <div class="formulas-trait__rarity" :class="rarity">{{ rarityName }}</div>
    </div>
</template>
<script>
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity"
import { computed, defineComponent, ref } from "vue"
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline"
import IOffChain from "@/assets/icons/off-chain.svg?inline"
import IPlus from "@/assets/icons/plus.svg?inline"
import IMinus from "@/assets/icons/minus-stroke.svg?inline"
import IRecent from "@/assets/icons/recent.svg?inline"
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
import ISend from "@/assets/icons/send.svg?inline"
import { resizeImageCF } from "@/helpers/resize-image-cloudflare"
import ACounter from "@/components/ACounter.vue"

export default defineComponent({
    components: { IWallet, ACounter, IRecent, ISend, ICheckmark, IOffChain, IPlus, IMinus },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        isSelected: Boolean,
        isSelectionMode: Boolean,
        selectedCount: {
            type: Number,
            default: 1
        }
    },
    setup(props, { emit }) {
        // add gender id, rarity, and balance to trait card
        const genderId = computed(() => props.item.genderId)
        const maleImage = computed(() => props.item.rarityMalePreviewImageSrc)
        const femaleImage = computed(() => props.item.rarityFemalePreviewImageSrc)
        const previewImage = computed(() => props.item.rarityUnisexPreviewImageSrc || maleImage.value || femaleImage.value || props.item.image)
        const rarityName = computed(() => props.item.rarity ? props.item.rarity.charAt(0).toUpperCase() + props.item.rarity.slice(1) : '')
        const totalBalance = computed(() => (props.item.offChain || 0) + (props.item.balance || 0))
        const rarity = computed(() => {
            if (props.item.rarity === ApeTraitRarity.og) return 'common'
            return props.item.rarity
        })
        const toggleSelect = () => {
            emit('select', props.isSelected ? 0 : 1)
        }
        const count = computed({
            get() {
                return props.selectedCount
            },
            set(val) {
                if (val > totalBalance.value || val < 1) return
                emit('select', val)
            }
        })


        return {
            previewImage, toggleSelect, count, totalBalance, resizeImageCF, rarityName, genderId, rarity
        }
    }
})
</script>
<style lang="scss">
.formulas-trait {
    padding: 10px;
    padding-top: 24px;
    padding-bottom: 28px;
    border-radius: 5px;
    position: relative;
    text-align: center;
    border: 1px solid transparent;

    &.selected {
        border: 1px solid var(--green);
    }

    &__category {
        text-transform: uppercase;
        font-weight: bold;
        padding: 5px 14px;
        box-sizing: border-box;
        background-color: var(--gray-light);
        display: inline-block;
        border-radius: 20px;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 50%;
        white-space: nowrap;
        transform: translate(-50%, -50%);
    }
    &-counter {
        margin-top: 10px;
    }
    &__info {
        margin-top: 5px;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__rank {
        white-space: nowrap;
        color: var(--primary);
    }

    &__counts {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(10px, -40%);
        display: flex;
        align-items: center;
    }
    &__count {
        background-color: var(--gray-light);
        border: var(--border);
        border-radius: 5px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 3px 5px;
        text-align: center;
        min-width: 26px;
        font-size: 14px;
        font-weight: bold;
        svg {
            margin-right: 2px;
        }
        &-onchain {
            color: var(--green);
        }

        &-offchain {
            color: var(--primary);
            margin-right: 8px;
        }
    }
    &__selected {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        &-icon {
            background-color: var(--green);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__rarity {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        position: absolute;
        height: 20px;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 14px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        &.common {
            background-color: var(--rarity-common);
        }
        &.epic {
            background-color: var(--rarity-epic);
        }
        &.legendary {
            background-color: var(--rarity-legendary);
        }
        &.utility {
            background-color: #2089df;
        }
    }
    &__button {
        padding: 5px 10px;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: var(--primary);
        text-decoration: none;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 10px;
        transition: 0.2s;
        line-height: 1;
        &:hover {
            filter: brightness(0.8);
        }
    }
    &__name {
        font-weight: bold;
        margin-right: 5px;
        text-align: left;
    }

    &__image {
        position: relative;
        cursor: pointer;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &.rarity-epic {
        box-shadow: 0 0 4px 0 var(--rarity-epic);
    }
    &.rarity-legendary {
        box-shadow: 0 0 4px 0 var(--rarity-legendary);
    }
    &.rarity-utility {
        box-shadow: 0 0 4px 0 #2089df;
    }
}
</style>
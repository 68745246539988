<template>
    <div class="formulas-preview-prize" :class="{ ['rarity-' + rarity]: true, blurred: isBlurred }">
        <div class="formulas-preview-prize__image">
            <img width="300" height="300" :src="image" alt="prize-image">
        </div>
        <div class="formulas-preview-prize__name">{{ item.name }}</div>
    </div>
</template>
<script setup>import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed } from "vue-demi";

const formulasStore = useFormulasStore()
const item = computed(() => formulasStore.activeFormula?.trait_out || {})
const image = computed(() => resizeImageCF(item.value.image, 512))
const rarity = computed(() => item.value.rarity)
const traitInWallet = computed(() => useWalletCollectionStore().apeTraits.find(trait => trait.id == item.value.id) || { offChain: 0, balance: 0 })
// const isBlurred = computed(() => !!formulasStore.activeFormula.team_type && traitInWallet.value.offChain < 1 && traitInWallet.value.balance < 1)
// don't blur in phase 2
const isBlurred = computed(() => {
    if(formulasStore.activeFormula?.phase > 6) return false
    return formulasStore.activeFormula?.phase >= useGodApeQuestStore().currentPhase && !useGodApeQuestStore().completedCombinations.includes(formulasStore.activeFormula?.combination_id)
})

</script>
<style lang="scss">
.formulas-preview-prize {
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    &__name {
        position: relative;
        z-index: 3;
    }
    &__image {
        position: relative;
        z-index: 3;
        margin-bottom: 8px;
        pointer-events: none;
        img {
            box-shadow: 0 0 44px 3px rgb(255 255 255 / 19%);
            border-radius: 5px;
            width: 250px;
            height: auto;
        }
    }
    &.rarity-epic & {
        &__image {
            img {
                border: 1px solid var(--rarity-epic);
            }
        }
    }
    &.rarity-legendary & {
        &__image {
            img {
                border: 1px solid var(--rarity-legendary);
            }
        }
    }
    &.blurred & {
        &__image,
        &__name {
            filter: blur(10px);
        }
    }
}
</style>
<template>
  <div
    class="formulas-ingredient"
    :class="{ satisfy: isSatisfy, error: !isSatisfy && !isSelectable }"
  >
    <div class="formulas-ingredient-state" v-if="isSatisfy || !isSelectable">
      <ICheckmark width="16" v-if="isSatisfy" />
      <ICross width="10" v-else />
    </div>
    <div
      class="formulas-ingredient-count"
      v-if="count > 1"
      v-tooltip="'You should have ' + count + ' such traits'"
    >
      x{{ count }}
    </div>
    <component
      :is="comp"
      :idx="idx"
      :type="type"
      :item="item"
      :is-satisfy="isSatisfy"
    />
  </div>
</template>
<script setup>
import { computed } from "vue-demi";
import ICheckmark from "@/assets/icons/checkmark.svg?inline";
import ICross from "@/assets/icons/cross.svg?inline";
import FormulasIngredientMethVue from "./FormulasIngredientMeth.vue";
import FormulasIngredientTrait from "./FormulasIngredientTrait.vue";
import FormulasIngredientForgeVue from "./FormulasIngredientForge.vue";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import FormulasIngredientUniversal from "./FormulasIngredientUniversal/FormulasIngredientUniversal.vue";
import FormulasIngredientUtility from "./FormulasIngredientUtility.vue";
import FormulasIngredientOGBull from "./FormulasIngredientOGBull/FormulasIngredientOGBull.vue";
import FormulasIngredientApe from "./FormulasIngredientApe/FormulasIngredientApe.vue";
import { useFormulasStore } from "@/store/formulas/formulas";
import FormulasIngredientMethMachineVue from "./FormulasIngredientMethMachine/FormulasIngredientMethMachine.vue";
import FormulasIngredientTeenResurrectionVue from "./FormulasIngredientResurrection/FormulasIngredientResurrection.vue";
import FormulasIngredientReplenishmentVue from "./FormulasIngredientReplenishment/FormulasIngredientReplenishment.vue";
import FormulasIngredientTeen from "./FormulasIngredientTeen/FormulasIngredientTeen.vue";
const props = defineProps({
  // meth/forge_block/trait/trait_category
  type: String,
  idx: Number,
  isSatisfy: Boolean,
  item: {
    type: Object,
    default: () => ({}),
  },
});
const formulasStore = useFormulasStore();
const comp = computed(() => {
  if (props.type === FormulasIngredientType.METH) {
    return FormulasIngredientMethVue;
  }
  if (props.type === FormulasIngredientType.TRAIT) {
    return FormulasIngredientTrait;
  }
  if (props.type === FormulasIngredientType.FORGE_BLOCK) {
    return FormulasIngredientForgeVue;
  }
  if (
    [
      FormulasIngredientType.TRAIT_CATEGORY,
      FormulasIngredientType.RARITY,
      FormulasIngredientType.FORMULA_TRAIT,
      FormulasIngredientType.TRAIT_ANY,
      FormulasIngredientType.TAGS,
    ].includes(props.type)
  ) {
    return FormulasIngredientUniversal;
  }
  if (props.type === FormulasIngredientType.UTILITY) {
    return FormulasIngredientUtility;
  }
  if (props.type === FormulasIngredientType.OG_BULL) {
    return FormulasIngredientOGBull;
  }
  if (props.type === FormulasIngredientType.APE) {
    return FormulasIngredientApe;
  }
  if (props.type === FormulasIngredientType.TEEN) {
    return FormulasIngredientTeen;
  }
  if (props.type === FormulasIngredientType.METH_MACHINE) {
    return FormulasIngredientMethMachineVue;
  }
  if (props.type === FormulasIngredientType.RESURRECTION) {
    return FormulasIngredientTeenResurrectionVue;
  }
  if (props.type === FormulasIngredientType.BREEDING_REPLENISHMENT) {
    return FormulasIngredientReplenishmentVue;
  }
});
const count = computed(() => {
  return props.item.count || 1;
});

const ingredientItem = computed(() => {
  return formulasStore.formulaIngredients[props.idx];
});
const isSelectable = computed(() => {
  return formulasStore.isSelectable(ingredientItem.value.type);
});
</script>
<style lang="scss">
.formulas-ingredient {
  border: 1px solid var(--border);
  border-radius: 5px;
  background-color: var(--gray-light);
  padding: 20px 10px;
  padding-bottom: 15px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  &-title {
    white-space: nowrap;
    text-transform: uppercase;
    opacity: 0.5;
    font-weight: bold;
  }
  &-count {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    color: #fff;
    border: 1px solid var(--red);
    background: var(--gray-light);
    border-radius: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    padding: 0 3px;
    box-sizing: border-box;
    height: 25px;
  }
  &-state {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    background: var(--red);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
  }
  &.satisfy {
    border-color: var(--green);
  }
  &.error {
    border: 1px solid var(--red);
  }
  &.satisfy & {
    &-state {
      background: var(--green);
    }
    &-count {
      border-color: var(--green);
    }
  }
  @media screen and (max-width: 860px) {
    width: 100%;
  }
}
</style>

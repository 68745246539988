<template>
    <FormulasIngredientCard
        :item="itemWithBalance"
        :isSatisfy="isSatisfy"
    ></FormulasIngredientCard>
</template>
<script setup>
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { computed } from "vue-demi";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import ApeTrait from "@/components/ApeTrait/ApeTrait.vue";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";
import FormulasIngredientCard from "./FormulasIngredientCard/FormulasIngredientCard.vue";
import utilitiesMetadata from "@/config/utilities-metadata";

const props = defineProps({
    item: {
        type: Object
    },
    isSatisfy: Boolean
})
const utilityItem = computed(() => {
    return utilitiesMetadata[props.item.id]
})
const itemWithBalance = computed(() => {
    const inWallet = useWalletCollectionStore().utilities.find(item => item.id == props.item.id) || {}
    return { ...utilityItem.value, ...inWallet }
})
const noTraits = computed(() => {
    return (itemWithBalance.value.offChain || 0) + (itemWithBalance.value.onChain || 0) < 1
})
</script>
<style lang="scss">

</style>
<template>
    <FormulasIngredientSelectable
        :items="items"
        :idx="idx"
        criteriaName="Meth Machine"
        :tooltipText="{ content: 'Any Meth Machine', html: true, distance: 10 }"
        selectText="Select Meth Machine"
        selectEmptyText="You don't have Meth Machines"
        v-model:selectedItem="formulasStore.selectedTraits[idx]"
    >
        <template #selected-item="{ item }">
            <FormulasIngredientMethMachineItem :isSatisfy="isSatisfy" :isSelectMode="false" :item="item" />
            <!-- <ApeTrait class="formulas-ingredient-selectable__preview" :item="item">
                <template #details>
                    <button class="ape-trait__button formulas-ingredient-selectable__button">Change</button>
                </template>
            </ApeTrait>-->
        </template>
        <template #select-item="itemInfo">
            <!-- <FormulasIngredientUniversalTrait v-bind="itemInfo" /> -->
            <FormulasIngredientMethMachineItem :isSelectMode="true" v-bind="itemInfo" />
        </template>
    </FormulasIngredientSelectable>
</template>
<script setup>
import { computed } from "vue-demi";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import FormulasIngredientMethMachineItem from "./FormulasIngredientMethMachineItem.vue";
import FormulasIngredientSelectable from "../FormulasIngredientSelectable/FormulasIngredientSelectable.vue";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
const props = defineProps({
    item: {
        type: Object
    },
    idx: Number,
    isSatisfy: Boolean
})
const formulasStore = useFormulasStore()
const selectedTrait = computed(() => {
    return formulasStore.selectedTraits[props.idx]
})


const items = computed(() => {
    // const items = []
    // useWalletCollectionStore().methMachines.forEach(item => {
    //     if (items.findIndex(itm => itm.guild === item.guild) < 0) {
    //         items.push(item)
    //     }
    // })
    const selectedTraits = Object.values(formulasStore.selectedTraits);
    const items = useWalletCollectionStore().methMachines.filter(
        (trait) => {
            return (
              selectedTraits.filter((t) => t.id == trait.id).length <
              trait.offChainCount + trait.onChainCount
            );
        }
    );

    return items
})


</script>
<style lang="scss">
</style>
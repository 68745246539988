import { useConfirmModal } from "@/composables/useConfirmModal";
import { useContract } from "@/composables/useContract";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useWeb3Store } from "@/store/web3";
import { useUtilitiesInteractionsContract, useUtilitiesContract } from "@/web3/contracts";



// [{id, amount}]
export const moveUtilitiesOffChain = async (utilities) => {
  try {
    const utilityIds = utilities.map((item) => item.id);
    const amounts = utilities.map((item) => item.amount);
   
    if (!(await approveBurnUtilities())) return;
    await useToastTransaction(
      useUtilitiesInteractionsContract(true).methods.burnUtilities(utilityIds, amounts),
      { text: "Moving utilities off-chain" }
    );
    return true;
  } catch (err) {
    captureError(err);
  }
};

async function approveBurnUtilities() {
  try {
    const wallet = useWeb3Store().walletAddress;
    const approveAddress = useUtilitiesInteractionsContract().options.address;
    const isApproved = await useUtilitiesContract()
      .methods.isApprovedForAll(wallet, approveAddress)
      .call();
    if (isApproved) return true;
    const confirmed = await useConfirmModal({
      title: "Contract needs your approval",
      text: `The contract requires your approval to use your Utilities tokens.
      <br><br>After you approve the contract, moving utilities off-chain will continue.`,
    });
    if (!confirmed) return false;
    const approveForAllResponse = await useToastTransaction(
      useUtilitiesContract(true).methods.setApprovalForAll(
        approveAddress,
        true
      ),
      {
        text: "Approving Utilities Contract",
        successText: "You have successfully approved Utilities Contract",
      }
    );
    return approveForAllResponse;
  } catch (err) {
    captureError(err);
  }
}

export const formatUtilities = (utilities) => {
  const formattedUtilities = utilities.reduce((acc, curr) => {
    const utilObject = {
      id: curr.id,
      categoryName: "utility",
      offChain: curr.offChainCount,
      balance: curr.onChainCount,
      name: `${curr.name} ${curr.guild}`,
      image: curr.image,
      genderId: 1,
      rarityMalePreviewImageSrc: curr.image,
      rarity: "utility"
    }

    acc.push(utilObject)

    return acc
  }, []) 

  return formattedUtilities;
};

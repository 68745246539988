<template>
  <div class="collection-ape-trait"
    :class="{ 'gender-both': genderId === 3, [`rarity-${rarity}`]: true, selected: isSelected }">
    <div class="collection-ape-trait__category">{{ item.categoryName }}</div>
    <div v-if="isNew" v-tooltip="{ content: 'Recently received', distance: 10 }" class="collection-ape-trait__new">
      <IRecent width="20" />
    </div>
    <div class="collection-ape-trait__counts">
      <div v-if="item.offChain >= 1"
        v-tooltip="'You have ' + item.offChain + ' off-chain trait' + (item.offChain > 1 ? 's' : '')"
        class="collection-ape-trait__count collection-ape-trait__count-offchain">
        <IOffChain width="14" />
        {{ item.offChain }}
      </div>
      <div v-if="item.balance >= 1"
        v-tooltip="'You have ' + item.balance + ' on-chain trait' + (item.balance > 1 ? 's' : '')"
        class="collection-ape-trait__count collection-ape-trait__count-onchain">
        <IWallet width="14" />
        {{ item.balance }}
      </div>
    </div>
    <div v-if="isSelected" class="collection-ape-trait__selected">
      <ICheckmark width="16" />
    </div>
    <div v-if="genderId === 3" class="collection-ape-trait__images">
      <div class="collection-ape-trait__image" @click.prevent="$emit('zoom', maleImage)">
        <img width="512" height="512" :src="resizeImageCF(maleImage, 512)" alt="male" loading="lazy">
        <div class="collection-ape-trait__image-info">Male</div>
      </div>
      <div class="collection-ape-trait__image" @click.prevent="$emit('zoom', femaleImage)">
        <img width="512" height="512" :src="resizeImageCF(femaleImage, 512)" alt="female" loading="lazy">
        <div class="collection-ape-trait__image-info">Female</div>
      </div>
    </div>
    <div v-else class="collection-ape-trait__images">
      <div class="collection-ape-trait__image">
        <img width="512" height="512" :src="resizeImageCF(previewImage, 512)" alt="preview" loading="lazy"
          @click.prevent="$emit('zoom', previewImage)">
        <div class="collection-ape-trait__image-info">{{ genderId === 1 ? 'Male' : 'Female' }}</div>
      </div>
    </div>
    <div class="collection-ape-trait__name">{{ item.name }}</div>
    <div class="collection-ape-trait__minted-count">
      #{{ item.id }} - RANK {{ item.rank }}
    </div>

    <div v-if="isSelectionMode" class="collection-ape-trait-counter">
      <a href="#" class="collection-ape-trait-counter-button" :class="{ disabled: selectedCount <= 1 }"
        @click.prevent="doMinus">
        <IMinus width="21" />
      </a>
      <div class="collection-ape-trait-counter-value">{{ selectedCount }}</div>
      <a href="#" class="collection-ape-trait-counter-button"
        :class="{ disabled: selectedCount >= (selectionMode === 'mint' ? item.offChain : item.balance) }"
        @click.prevent="doPlus">
        <IPlus width="20" />
      </a>
    </div>
    <div v-if="!isSelectionMode" class="collection-ape-trait__minted-count">
      {{ item.total_minted }} / {{ Math.max(item.max_quantity, item.total_minted) }} Minted
    </div>
    <div class="collection-ape-trait__action">
      <a v-if="!isSelectionMode && item.offChain > 0" class="collection-ape-trait__button" href="#"
        @click.prevent="mintTrait">Mint Trait</a>

      <a v-if="!isSelectionMode && item.offChain > 0" v-tooltip="{ content: 'Send Trait', distance: 10 }" href="#"
        class="collection-ape-trait__send" @click.prevent="sendTrait">
        <ISend width="12" />
      </a>
      <a v-if="isSelectionMode" class="collection-ape-trait__button" href="#"
        @click.prevent="$emit('select', isSelected ? 0 : 1)">{{ isSelected ? 'De-select Trait' : 'Select Trait' }}</a>
    </div>

    <a class="collection-ape-trait__move-offchain collection-ape-trait__button" href="#" @click.prevent="moveOffChain"
      v-if="item.balance > 0 && !isSelectionMode">Move to Trait Bank</a>
    <div class="collection-ape-trait__sell-order" v-if="sellOrder">
      <div class="collection-ape-trait__sell-order-title">For sale</div>
      <div class="collection-ape-trait__sell-order-value">
        <span>{{ sellOrder.price }} METH</span>
        x{{ sellOrder.amount }}
      </div>
    </div>

    <a class="collection-ape-trait__sell collection-ape-trait__button" href="#" @click.prevent="sellTrait"
      v-if="(item.balance > 0 || item.offChain > 0) && !isSelectionMode">{{ sellOrder ? 'Update price' : 'List for sale'
      }}</a>
    <a class="collection-ape-trait__delist collection-ape-trait__button" href="#" @click.prevent="deListTrait"
      v-if="!!sellOrder && !isSelectionMode">De-list from sale</a>
    <div class="collection-ape-trait__rarity" :class="rarity">{{ rarityName }}</div>
    <div class="collection-ape-trait__buy-order" v-if="buyOrder">
      <div class="collection-ape-trait__buy-order-title">Biggest Offer</div>
      <div class="collection-ape-trait__buy-order-value">
        <span>{{ buyOrder.price }} METH</span>
        x{{ buyOrder.amount }}
      </div>
      <ACounter :style="{marginTop: '5px'}" :max="acceptOrderMax" v-model:value="acceptOrderCount" v-if="acceptOrderMax > 1"/>
      <a class="collection-ape-trait__buy collection-ape-trait__button" href="#"
        @click.prevent="acceptSellOffer">Accept</a>
    </div>
  </div>
</template>
<script>
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity"
import { computed, defineComponent, ref } from "vue"
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline"
import IOffChain from "@/assets/icons/off-chain.svg?inline"
import IPlus from "@/assets/icons/plus.svg?inline"
import IMinus from "@/assets/icons/minus-stroke.svg?inline"
import IRecent from "@/assets/icons/recent.svg?inline"
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
import ISend from "@/assets/icons/send.svg?inline"
import { resizeImageCF } from "@/helpers/resize-image-cloudflare"
import { useModal } from "@/composables/useModal"
import { ModalName } from "@/components/Modal/modal-name"
import { useWalletCollectionStore } from "@/store/wallet-collection"
import { traitMarketplaceRemove } from "@/functions/trait-marketplace/trait-marketplace-remove"
import { traitMarketplaceBuy } from "@/functions/trait-marketplace/trait-marketplace-buy"
import ACounter from "@/components/ACounter.vue"

export default defineComponent({
  components: { IWallet, IRecent, ISend, ICheckmark, IOffChain, IPlus, IMinus, ACounter },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isSelected: Boolean,
    isSelectionMode: Boolean,
    selectionMode: String,
    selectedCount: {
      type: Number,
      default: 1
    },
  },
  setup(props, { emit }) {
    // add gender id, rarity, and balance to trait card
    const genderId = computed(() => props.item.genderId)
    const maleImage = computed(() => props.item.malePreviewImageSrc)
    const femaleImage = computed(() => props.item.femalePreviewImageSrc)
    const previewImage = computed(() => maleImage.value || femaleImage.value)
    const rarityName = computed(() => props.item.rarity ? props.item.rarity.charAt(0).toUpperCase() + props.item.rarity.slice(1) : '')
    const rarity = computed(() => {
      if (props.item.rarity === ApeTraitRarity.og) return 'common'
      return props.item.rarity
    })
    const sellOrder = computed(() => props.item.sellOrder)
    const buyOrder = computed(() => props.item.buyOrder)
    const acceptOrderCount = ref(1)
    const acceptOrderMax = computed(() => Math.min(buyOrder.value?.amount, props.item.offChain))
    const mintTrait = () => {
      emit('mint')
    }
    const moveOffChain = () => {
      emit('moveOffChain')
    }
    const sellTrait = () => {
      const onSuccess = () => {
        emit('refresh')
      }
      if (sellOrder.value) {

        useModal().open({ name: ModalName.SellTraitModal, props: { id: props.item.id, offerId: sellOrder.value._id, price: sellOrder.value.price, amount: sellOrder.value.amount, onSuccess }, })
      } else {
        useModal().open({ name: ModalName.SellTraitModal, props: { id: props.item.id, onSuccess } })
      }
    }
    const deListTrait = async () => {
      await traitMarketplaceRemove({ offerId: sellOrder.value._id, id: props.item.id })
      emit('refresh')
    }
    const acceptSellOffer = async () => {
      const success = await traitMarketplaceBuy({
        isUtility: false,
        offerId: buyOrder.value._id, amount: acceptOrderCount.value, id: props.item.id, isSelling: true
      })
      if (success) {
        emit('refresh')
      }

    }
    const doPlus = () => {
      if (props.selectionMode === 'mint') {
        if (props.selectedCount >= props.item.offChain) return
      } else {
        if (props.selectedCount >= props.item.balance) return
      }
      emit('select', props.selectedCount + 1)
    }
    const doMinus = () => {
      if (props.selectedCount <= 1) return
      emit('select', props.selectedCount - 1)
    }
    const isNew = computed(() => props.item.newly_minted)
    const sendTrait = () => {
      useModal().open({
        name: ModalName.SendTrait, props: {
          maxAmount: props.item.offChain,
          item: props.item, onSuccess: () => {
            useWalletCollectionStore().fetchApeTraits()
          }
        }
      })
    }
    return {
      previewImage,acceptOrderMax,  acceptOrderCount, acceptSellOffer, isNew, buyOrder, sellOrder, deListTrait, moveOffChain, sendTrait, sellTrait, resizeImageCF, mintTrait, doPlus, doMinus, rarityName, genderId, maleImage, femaleImage, rarity
    }
  }
})
</script>
<style lang="scss">
.collection-ape-trait {
  border: 1px solid var(--border);
  padding: 10px;
  padding-top: 24px;
  padding-bottom: 28px;
  border-radius: 5px;
  box-shadow: 0 0 4px 3px var(--rarity-common);
  position: relative;

  &.selected {
    border: 1px solid var(--green);
  }

  &__new {
    position: absolute;

    left: -10px;
    top: -10px;
    color: var(--yellow);
    background-color: var(--gray-light);
    padding: 3px;
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__category {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 14px;
    box-sizing: border-box;
    background-color: var(--gray-light);
    display: inline-block;
    border-radius: 20px;
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
  }

  &__send {
    border: 1px solid var(--border);
    border-radius: 5px;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    color: #fff;
    transition: 0.2s;

    &:hover {
      background-color: var(--gray-light);
    }
  }

  &__minted-count {
    font-size: 12px;
    opacity: 0.5;
  }

  &__counts {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(10px, -40%);
    display: flex;
    align-items: center;
  }

  &__count {
    background-color: var(--gray-light);
    border: var(--border);
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 3px 5px;
    text-align: center;
    min-width: 26px;
    font-size: 16px;
    font-weight: bold;

    svg {
      margin-right: 2px;
    }

    &-onchain {
      color: var(--green);
    }

    &-offchain {
      color: var(--primary);
      margin-right: 8px;
    }
  }

  &__selected {
    position: absolute;
    left: -10px;
    top: -10px;
    width: 25px;
    height: 25px;
    background-color: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &-counter {
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    box-sizing: border-box;
    background-color: var(--gray-light);
    border-radius: 5px;
    margin-top: 5px;

    &-value {
      margin: 0 5px;
      line-height: 1;
      min-width: 20px;
    }

    &-button {
      color: #fff;
      user-select: none;
      display: block;
      font-size: 0;

      &:hover {
        color: var(--primary);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  &__rarity {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: absolute;
    height: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 14px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    &.common {
      background-color: var(--rarity-common);
    }

    &.epic {
      background-color: var(--rarity-epic);
    }

    &.legendary {
      background-color: var(--rarity-legendary);
    }
  }

  &__button {
    padding: 5px 10px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--primary);
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 10px;
    transition: 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &__sell,
  &__buy {
    width: 155px;
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);

    &-order {
      padding-top: 3px;
      margin-top: 8px;
      border-top: 1px solid var(--border);

      &-title {
        opacity: 0.5;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }

      &-value {
        font-weight: bold;

        span {
          color: var(--yellow);
        }
      }
    }
  }

  &__delist {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 155px;
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
  }

  &__move-offchain {
    border: 1px solid var(--primary);
    background-color: transparent;
    color: var(--primary);
  }

  &__name {
    font-weight: bold;
    color: var(--primary);
    margin-top: 5px;
  }

  &__images {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
      width: 100%;
      border-radius: 5px;
    }
  }

  &__image {
    cursor: zoom-in;
    max-width: 50%;

    &-info {
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &.gender-both & {
    &__images {
      img {}
    }

    &__image {
      flex: 1;

      &:first-child {
        margin-right: 5px;
      }
    }
  }

  &.rarity-epic {
    box-shadow: 0 0 4px 0 var(--rarity-epic);
  }

  &.rarity-legendary {
    box-shadow: 0 0 4px 0 var(--rarity-legendary);
  }
}
</style>
<template>
    <FormulasIngredientSelectable
        :items="items"
        :idx="idx"
        :criteriaName="criteriaName"
        :tooltipText="tooltipText"
        selectEmptyText="You don't have traits matching this criteria"
        selectText="Select Trait"
        v-model:selectedItem="formulasStore.selectedTraits[idx]"
    >
        <template #selected-item="{ item }">
            <ApeTrait class="formulas-ingredient-selectable__preview" :item="item">
                <template #details>
                    <button class="ape-trait__button formulas-ingredient-selectable__button">Change</button>
                </template>
            </ApeTrait>
        </template>
        <template #select-item="itemInfo">
            <FormulasIngredientUniversalTrait v-bind="itemInfo" />
        </template>
    </FormulasIngredientSelectable>
</template>
<script setup>
import { computed } from "vue-demi";
import IPlus from "@/assets/icons/plus-stroke.svg?inline"
import IInfo from "@/assets/img/info.svg?inline"
import { useFormulasStore } from "@/store/formulas/formulas";
import ApeTrait from "@/components/ApeTrait/ApeTrait.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
import { ACHIEVEMENT_FLAG_ID } from "@/helpers/achievement-flag";
import FormulasIngredientSelectable from "../FormulasIngredientSelectable/FormulasIngredientSelectable.vue";
import FormulasIngredientUniversalTrait from "./FormulasIngredientUniversalTrait.vue";
const props = defineProps({
    item: {
        type: Object
    },
    // category/rarity/formula_trait
    type: String,
    idx: Number
})
const formulasStore = useFormulasStore()
const selectedTrait = computed(() => {
    return formulasStore.selectedTraits[props.idx]
})
const openSelection = () => {

}

const items = computed(() => {
    // get rid of offChain filter
    const selectedTraits = Object.values(formulasStore.selectedTraits)
    const traits = useWalletCollectionStore().apeTraits.filter(trait => {
        // exclude achievement flag
        if (trait.id == ACHIEVEMENT_FLAG_ID) return false
        return selectedTraits.filter(t => t.id == trait.id).length < (trait.offChain + trait.balance)
    })
    if (props.type === FormulasIngredientType.TRAIT_CATEGORY) {
        return traits.filter(trait => {
            return trait.categoryId == props.item.id
        })
    }
    if (props.type === FormulasIngredientType.RARITY) {
        return traits.filter(trait => trait.rarity == props.item.name)
    }
    if (props.type === FormulasIngredientType.FORMULA_TRAIT) {
        const itms = traits.filter(trait => {
            if (trait.team_type && trait.team_type !== 'all') return false
            if (props.item.name === 'all') {
                return !!trait.is_formula_trait
            } else {
                return trait.team_type === props.item.name
            }
        }
        )
        return itms
    }
    if (props.type === FormulasIngredientType.TRAIT_ANY) {
        return traits
    }
    if(props.type === FormulasIngredientType.TAGS) {
        return traits.filter(trait => trait.tags?.includes(props.item.name))
    }
    return []
})

const criteriaName = computed(() => {
    if (props.type === FormulasIngredientType.TRAIT_CATEGORY) {
        return `Category: ${props.item.name}`
    }
    if (props.type === FormulasIngredientType.RARITY) {
        return `Rarity: ${props.item.name}`
    }
    if (props.type === FormulasIngredientType.TRAIT_ANY) {
        return "Any trait"
    }
    if (props.type === FormulasIngredientType.FORMULA_TRAIT) {
        if (props.item.name === 'all') {
            return "Forged Trait"
        }
        return `Team: ${props.item.name[0]?.toUpperCase() + props.item.name?.slice(1)}`
    }
    if(props.type === FormulasIngredientType.TAGS) {
        return `Sub Category: ${props.item.name}`
    }
    return ''
})
const tooltipText = computed(() => {
    if (props.type === FormulasIngredientType.FORMULA_TRAIT) {
        if (props.item.name === 'all') {
            return 'Any trait created using a standalone formula'
        }
    }
    if (props.type === FormulasIngredientType.RARITY) {
        if (props.item.name === ApeTraitRarity.og) {
            return 'Any trait available on an Ape during the original mint'
        }
        return `Any ${props.item.name[0].toUpperCase() + props.item.name.slice(1)} trait`
    }
    if (props.type === FormulasIngredientType.TRAIT_CATEGORY) {
        return `Any ${props.item.name} trait`
    }
    if (props.type === FormulasIngredientType.TAGS) {
        return `Any ${props.item.name[0].toUpperCase() + props.item.name.slice(1)} trait`

    }
})
</script>
<style lang="scss">
</style>
import { ApeTraitRarity } from "./ape-constructor/ape-trait-rarity";

export const estimateForgeAmount = (traits) => {
  let blocksToForge = 0;
  for (let i = 0; i < traits.length; i++) {
    const rarity = traits[i].rarity;
    for (let j = 0; j < (traits[i].count || 1); j++) {
      blocksToForge += 1;
      // switch (rarity) {
      //   case ApeTraitRarity.common:
      //     blocksToForge += 1;
      //     break;
      //   case ApeTraitRarity.og:
      //     blocksToForge += 2;
      //     break;
      //   case ApeTraitRarity.epic:
      //     blocksToForge += 3;
      //     break;
      //   case ApeTraitRarity.legendary:
      //     blocksToForge += 5;
      //     break;
      // }
    }
  }
  return blocksToForge;
};

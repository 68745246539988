<template>
  <FormulasIngredientSelectable
    :items="items"
    :idx="idx"
    criteriaName="Any Ape"
    selectText="Select Any Ape</span>"
    selectEmptyText="You don't have Apes available"
    v-model:selectedItem="formulasStore.selectedTraits[idx]"
  >
    <template #selected-item="{ item }">
      <FormulasIngredientApeItem
        :isSatisfy="isSatisfy"
        :isSelectMode="false"
        :item="item"
      ></FormulasIngredientApeItem>
      <!-- <ApeTrait class="formulas-ingredient-selectable__preview" :item="item">
                <template #details>
                    <button class="ape-trait__button formulas-ingredient-selectable__button">Change</button>
                </template>
            </ApeTrait>-->
    </template>
    <template #select-item="itemInfo">
      <!-- <FormulasIngredientUniversalTrait v-bind="itemInfo" /> -->
      <FormulasIngredientApeItem :isSelectMode="true" v-bind="itemInfo" />
    </template>
  </FormulasIngredientSelectable>
</template>
<script setup>
import { computed } from "vue-demi";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import FormulasIngredientApeItem from "./FormulasIngredientApeItem.vue";
import FormulasIngredientSelectable from "../FormulasIngredientSelectable/FormulasIngredientSelectable.vue";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
const props = defineProps({
  item: {
    type: Object,
  },
  idx: Number,
  isSatisfy: Boolean,
});
const formulasStore = useFormulasStore();
const selectedTrait = computed(() => {
  return formulasStore.selectedTraits[props.idx];
});

const items = computed(() => {
  const selectedTraits = formulasStore.selectedTraits;
  const apeIngredientsIndexes = formulasStore.formulaIngredients
    .filter((ingredient) => ingredient.type === FormulasIngredientType.APE)
    .map((item) => item.idx);

  const selectedApes = apeIngredientsIndexes
    .filter((ingredientIdx) => {
      return !!selectedTraits[ingredientIdx] && ingredientIdx !== props.idx;
    })
    .map((ingredientIdx) => selectedTraits[ingredientIdx]);
  const availableApes = useWalletCollectionStore().apes.filter((ape) => {
    return selectedApes.findIndex((b) => b.id === ape.id) < 0;
  });

  // remove name field availableApes
  availableApes.forEach((ape) => {
    delete ape.name;
  });

  // if (selectedBulls.length === 1) {
  //     return availableApes.filter(bull => bull.guild === selectedBulls[0].guild)
  // }

  return availableApes;
});
</script>
<style lang="scss"></style>

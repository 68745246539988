import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { signMessage } from "@/helpers/sign-message";
import { useWeb3Store } from "@/store/web3";

// [{id, count}]
export const forgeBlocksOffChain = async (traits) => {
  try {
    const signature = await signMessage("I'm minting forge blocks");
    if (!signature) return;
    const traitsIn = traits.reduce((arr, trait) => {
      const traitArr = [];
      for (let i = 0; i < trait.count; i++) {
        if(trait.rarity === "utility"){
          traitArr.push({id: trait.id, type: trait.rarity});
          continue;
        }
        traitArr.push(trait.id);
      }
      return arr.concat(traitArr);
    }, []);
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/forge/blocks",
      method: "POST",
      data: {
        wallet: useWeb3Store().collectionWallet,
        traitsIn,
        signature,
        network: Config.network.name,
      },
    });
    return res;
  } catch (err) {
    captureError(err);
  }
};

<template>
  <FormulasIngredientSelectable
    :items="items"
    :idx="idx"
    criteriaName="Any Teen"
    tooltipText="Bulk burn teens to save gas from MyCollection"
    selectText="Select Any Teen</span>"
    selectEmptyText="You don't have Teens available"
    v-model:selectedItem="formulasStore.selectedTraits[idx]"
  >
    <template #selected-item="{ item }">
      <FormulasIngredientTeenItem :isSatisfy="isSatisfy" :isSelectMode="false" :item="item"></FormulasIngredientTeenItem>
      <!-- <ApeTrait class="formulas-ingredient-selectable__preview" :item="item">
                <template #details>
                    <button class="ape-trait__button formulas-ingredient-selectable__button">Change</button>
                </template>
      </ApeTrait>-->
    </template>
    <template #select-item="itemInfo">
      <!-- <FormulasIngredientUniversalTrait v-bind="itemInfo" /> -->
      <FormulasIngredientTeenItem :isSelectMode="true" v-bind="itemInfo" />
    </template>
  </FormulasIngredientSelectable>
</template>
<script setup>
import { computed } from "vue-demi";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import FormulasIngredientTeenItem from "./FormulasIngredientTeenItem.vue";
import FormulasIngredientSelectable from "../FormulasIngredientSelectable/FormulasIngredientSelectable.vue";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
const props = defineProps({
  item: {
    type: Object,
  },
  idx: Number,
  isSatisfy: Boolean,
});
const formulasStore = useFormulasStore();
const selectedTrait = computed(() => {
  return formulasStore.selectedTraits[props.idx];
});

const items = computed(() => {
  const selectedTraits = formulasStore.selectedTraits;
 const availableApes = [
    ...useWalletCollectionStore().teens,
  ]
  .filter((teen) => !teen.isResurrected)
  .sort((a, b) => {
    if (a.isBurned && !b.isBurned) return -1
    if (!a.isBurned && b.isBurned) return 1
    return 0
  })


  // if (selectedBulls.length === 1) {
  //     return availableApes.filter(bull => bull.guild === selectedBulls[0].guild)
  // }

  return availableApes;
});
</script>
<style lang="scss"></style>

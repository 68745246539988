<template>
    <div class="formulas-gac">
        <FormulasGAQGodApe :items="godBullsFormulas" @select="$emit('select', $event)" />
        <FormulasGAQPhase
            v-for="(phase, idx) in phaseItems"
            :key="idx"
            :phase="phase"
            @select="$emit('select', $event)"
        />
    </div>
</template>
<script setup>
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed } from "vue-demi";
import FormulasFormulaCard from "../FormulasFormulaCard/FormulasFormulaCard.vue";
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
import FormulasGAQPhase from "./FormulasGAQPhase.vue";
import FormulasGAQGodApe from "./FormulasGAQGodApe.vue";
const props = defineProps({ items: Array })
defineEmits(['select'])

const userTeam = computed(() => useGodApeQuestStore().team)
const userPhase = computed(() => useGodApeQuestStore().currentPhase)
const godBullsFormulas = computed(() => props.items.filter(item => item.phase === 7))
const phaseItems = computed(() => {
    const phaseItems = []
    props.items.forEach(item => {
        const idx = (item.phase || 1) - 1
        // skip god apes
        if (idx >= 6) return
        if (!phaseItems[idx]) {
            phaseItems[idx] = {}
        }
        if (!phaseItems[idx][item.team_type]) {
            phaseItems[idx][item.team_type] = { items: [], completed: 0 }
        }
        const traitInWallet = useWalletCollectionStore().apeTraits.find(trait => trait.id == item.trait_out.id)
        const hasBeenCompleted = useGodApeQuestStore().completedCombinations.includes(item.combination_id)
        if (hasBeenCompleted || (traitInWallet && (traitInWallet.balance > 0 || traitInWallet.offChain > 0))) {
            phaseItems[idx][item.team_type].completed++
        }
        phaseItems[idx][item.team_type].items.push(item)
    })

    return phaseItems.map((phaseItem, idx) => {
        Object.keys(phaseItem).map(key => {
            let itemsToComplete = phaseItem[key].items.length
            if (idx === 4) {
                itemsToComplete = 1
            }
            phaseItem[key].itemsToComplete = itemsToComplete
        })
        return { ...phaseItem, phaseNumber: idx + 1 }
    }).reverse()
})
</script>
<style lang="scss">
.formulas-gac {
    overflow: auto;
    max-height: 80vh;
    margin: -10px;
    margin-top: 30px;
    margin-bottom: 0;
    &-items {
        justify-content: center;
        overflow: visible !important;
        max-height: none !important;
        margin: 0 !important;
    }
    &-phase {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        &__title {
            text-align: center;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            font-size: 22px;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 10px;
            padding: 5px 0;

            span {
                margin: 0 10px;
            }
            &::before {
                content: "";
                height: 1px;
                display: block;
                background: rgba(255, 255, 255, 0.5);
                flex: 1;
            }
            &::after {
                content: "";
                height: 1px;
                display: block;
                background: rgba(255, 255, 255, 0.5);
                flex: 1;
            }
        }
        &-team {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            &-vyl & {
                &__title {
                    color: var(--ape-quest-vyl);
                }
            }
            &-ava & {
                &__title {
                    color: var(--ape-quest-ava);
                }
            }
            &-all & {
                &__title {
                }
            }
            &__title {
                text-align: center;
                font-size: 18px;
                margin-bottom: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__info {
                margin-left: 5px;
                svg {
                    transform: translateY(2px);
                }
            }
            &__progress {
            }
            &__completed {
                border: 1px solid var(--green);
                color: var(--green);
                padding: 1px 5px;
                box-sizing: border-box;
                border-radius: 5px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            & &__item {
                width: calc(100% / 2 - 20px);
                @media screen and (max-width: 1200px) {
                    width: calc(100% / 2 - 20px);
                }
                @media screen and (max-width: 576px) {
                    width: 100%;
                    margin: 10px 0;
                }
            }
        }
    }
}
</style>
<template>
    <ApeTrait
        class="formulas-ingredient-category-trait"
        :is-selected="isSelected"
        :is-selection-mode="true"
        :show-counter="false"
        :item="item"
        :can-deselect="false"
        @select="toggleSelect"
    />
</template>
<script>
import { computed, defineComponent, ref } from "vue"

import ApeTrait from "@/components/ApeTrait/ApeTrait.vue"

export default defineComponent({
    components: { ApeTrait },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        isSelected: Boolean,
    },
    emits: ['select'],
    setup(props, { emit }) {
        const toggleSelect = () => {
            emit('select', props.isSelected ? false : true)
        }
        return {
            toggleSelect,
        }
    }
})
</script>
<style lang="scss">
.formulas-ingredient-category-trait {
    background: #000;
    font-family: var(--font-oxanium);
    font-weight: bold;
}
</style>
<template>
    <div class="formulas-ingredient-trait-wrapper">
        <ApeTrait class="formulas-ingredient-trait" :item="itemWithBalance">
            <template #details>
                <div
                    class="formulas-ingredient-trait-wallet"
                    v-if="noTraits"
                    v-tooltip="`You don't have this trait in the wallet`"
                >
                    <IWallet width="16" />
                </div>
            </template>
        </ApeTrait>
        <div class="formulas-ingredient-trait-get" v-if="noTraits && (getItName || apesTraits.length)">
            <div class="formulas-ingredient-trait-get-title">Where to get it?</div>
            <div class="formulas-ingredient-trait-get-items">
                <a
                    class="formulas-ingredient-trait-get-link"
                    href="javascript:void(0)"
                    @click.prevent="openGetIt"
                    v-if="getItName"
                >{{ getItName }}</a>
                <div class="formulas-ingredient-trait-get-ape" v-if="apesTraits.length">
                    <div class="formulas-ingredient-trait-get-ape-title">Equipped on Ape</div>
                    <div class="formulas-ingredient-trait-get-ape-items">
                        <a
                            :href="$router.resolve({ name: 'ApeConfigurator', params: { id: ape.id } }).fullPath"
                            target="_blank"
                            class="formulas-ingredient-trait-get-ape-item"
                            v-for="(ape, idx) in apesTraits.slice(0, 2)"
                            :key="idx"
                        >
                            <img
                                class="formulas-ingredient-trait-get-ape-image"
                                :src="ape.image"
                                alt="ape-image"
                                width="40"
                                height="40"
                            />
                            <div class="formulas-ingredient-trait-get-ape-id">#{{ ape.originalId }}</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { computed } from "vue-demi";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import ApeTrait from "@/components/ApeTrait/ApeTrait.vue";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";
import { useRouter } from "vue-router";
import { useFormulasStore } from "@/store/formulas/formulas";

const props = defineProps({
    item: {
        type: Object
    },
    isSatisfy: Boolean
})
const router = useRouter()
const itemWithBalance = computed(() => {
    const inWallet = useWalletCollectionStore().apeTraits.find(trait => trait.id == props.item.id) || {}
    return { ...props.item, ...inWallet }
})
const noTraits = computed(() => {
    return (itemWithBalance.value.offChain || 0) + (itemWithBalance.value.balance || 0) < 1
})
const apesTraits = computed(() => {
    const apesWithTrait = useWalletCollectionStore().allApes.filter(ape => {
        const hasOnApe = Object.values(ape.traits).findIndex(trait => trait === props.item.name) > -1
        return hasOnApe
    })
    return apesWithTrait
})
const apeWithTrait = computed(() => apesTraits.value[0])
const source = computed(() => {
    return props.item.source
})
const getItName = computed(() => {
    if (source.value === 'lootbox' && props.item.rarity !== ApeTraitRarity.og) {
        return "Get it from LootBox"
    }
    if (source.value === 'shop') {
        return "Get it from Shop"

    }
    if (source.value === 'achievements') {
        return "Get it from Achievements"

    }
    if (source.value === 'formula') {
        return "Forge It"
    }
    return null
})
const openGetIt = () => {
    if (source.value === 'lootbox') {
        window.open(router.resolve({ name: "LootBox" }).fullPath, '_blank')
    }
    if (source.value === 'shop') {
        window.open(router.resolve({ name: "TraitShop" }).fullPath, '_blank')
    }
    if (source.value === 'formula') {
        const formulaToSelect = useFormulasStore().formulas.find(formula => formula.trait_out.id == props.item.id)
        if (formulaToSelect) {
            useFormulasStore().selectFormula(formulaToSelect.combination_id)
        }
        return
    }
    if (source.value === 'achievements') {
        window.open(router.resolve({ name: "Achievements" }).fullPath, '_blank')
    }
}
const openApe = (apeId) => {
}
</script>
<style lang="scss">
.formulas-ingredient-trait {
    background: black;
    &-wallet {
        position: absolute;
        top: -6px;
        right: -4px;
        background: var(--gray-light);
        border: 1px solid var(--border);
        color: var(--red);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-get {
        margin-top: 8px;
        padding: 5px 0;
        text-align: center;
        font-size: 14px;
        &-title {
            text-transform: uppercase;
            opacity: 0.5;
            text-align: center;
            font-size: 14px;
        }
        &-link {
            color: var(--primary);
        }
        &-ape {
            border-top: 1px solid var(--border);
            padding-top: 5px;
            margin-top: 5px;
            &:only-child {
                border-top: none;
            }
            &-title {
                text-align: center;
                text-transform: uppercase;
                opacity: 0.5;
                margin-bottom: 5px;
            }
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px;
                color: #fff;
                text-decoration: none;

                &:hover {
                    color: var(--primary);
                }
            }
            &-items {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &-id {
            }
            &-image {
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }
}
</style>
import { useConfirmModal } from "@/composables/useConfirmModal";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import {  useAssetsInteractionsContract } from "@/web3/contracts";
import { approveApes } from "./approveApes";

export const burnApes = async function (apes) {
  try {
    const apesToBurn = apes.filter((ape) => !ape.isBurned);

    if (apesToBurn.length === 0) return;

    const apesApproved = await approveApes(
      "burn Apes for Phase 5"
    );
    if (!apesApproved) return;
    const contract = useAssetsInteractionsContract(true);

    const ids = apes.map((ape) => ape.id);

    const userAgrees = await useConfirmModal({
      title: "Burning Apes",
      text: `You are about to burn ${ids.length} Apes
              <br>This is required in order to proceed with the phase
              <br>After transaction is confirmed, the process will continue as usual`,
    });
    
    if (!userAgrees) return false;

    const burnResponse = await useToastTransaction(
      contract.methods.burnApes(ids),
      {
        text: "Burning Apes",
      }
    );
    useWalletCollectionStore().fetchCollection(true);
    useWeb3Store().update();
    return burnResponse;
  } catch (err) {
    captureError(err);
  }
};

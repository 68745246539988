import { FormulasIngredientType } from "./formulas-ingredient-types";

export const formulasBuildFormulaIngredients = (formula) => {
  const items = [];
  if (formula.blocks_required > 0) {
    items.push({
      type: FormulasIngredientType.FORGE_BLOCK,
      item: { value: formula.blocks_required },
    });
  }
  if (formula.meth_required > 0) {
    items.push({
      type: FormulasIngredientType.METH,
      item: { value: formula.meth_required },
    });
  }
  formula.traits_in.map((item) => {
    items.push({
      type: item.type ? item.type : FormulasIngredientType.TRAIT,
      item,
    });
});
return items.map((item, idx) => ({ ...item, idx }));
};

<template>
    <div class="formulas-ingredient-selectable-select">
        <div class="formulas-ingredient-selectable-select-row" v-if="items.length > 0">
            <div
                class="formulas-ingredient-selectable-select__item"
                v-for="(item) in items"
                :key="item.id"
            >
                <slot :item="item" :isSelected="isSelected(item)" @select="selectItem(item)"></slot>
            </div>
        </div>
        <div class="formulas-ingredient-selectable-select__empty" v-else>{{ emptyText }}</div>
    </div>
</template>
<script setup>import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed } from "vue";
import { hideAllPoppers } from 'floating-vue'

const props = defineProps({
    items: Array,
    emptyText: String
})
const emit = defineEmits(['select'])
const formulasStore = useFormulasStore()
const isSelected = (item) => {
    return formulasStore.selectedTraits[props.idx]?.id == item.id
}
const selectItem = item => {
    emit('select', item)
    hideAllPoppers()
}
</script>
<style lang="scss">
.formulas-ingredient-selectable-select {
    max-height: 400px;
    max-width: 400px;
    min-height: 200px;
    overflow: auto;
    padding: 10px 5px;

    &-row {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        margin: 10px 5px;
        width: calc(50% - 10px);
    }
    &__empty {
        height: 200px;
        opacity: 0.5;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
}
</style>
<template>
    <div class="formulas-ingredient-meth">
        <div class="formulas-ingredient-meth-row">
            <img src="@/assets/img/meth.png" alt="meth" width="20" />
            <div class="formulas-ingredient-title formulas-ingredient-meth-title">METH</div>
            <div class="formulas-ingredient-meth-value">{{ item.value }}</div>
        </div>
        <div class="formulas-ingredient-meth-buy" v-if="!isSatisfy">
            <a
                href="javascript:void(0)"
                class="formulas-ingredient-meth-buy-button"
                @click.prevent="buyMeth"
            >
                Buy Meth
                <IPlus width="14" />
            </a>
        </div>
    </div>
</template>
<script setup>import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import IPlus from "@/assets/icons/plus.svg?inline"
defineProps({
    item: Object,
    isSatisfy: Boolean
})
const buyMeth = () => {
    useModal().open({ name: ModalName.BuyMethModal })
}
</script>
<style lang="scss">
.formulas-ingredient-meth {
    flex: 1;
    
    &-row {
        display: flex;
        align-items: center;
        width: 100%;
    }
    &-title {
        margin-right: 5px;
    }
    &-value {
        margin-left: auto;
    }
    img {
        margin-right: 5px;
    }
    &-buy {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid var(--border);
        margin-bottom: -12px;
        text-transform: uppercase;
        font-size: 14px;
        &-button {
            color: var(--primary);
            margin-left: 5px;
            display: flex;
            justify-content: center;
            text-decoration: none;
            align-items: center;
            svg {
                margin-left: 3px;
                transform: translateY(-1px);
            }
        }
    }
}
</style>
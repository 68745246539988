<template>
    <div class="formulas-burn">
        <h2 class="formulas-burn__title">Burn traits and receive Forge blocks</h2>
        <div class="formulas-burn__items" v-if="!isLoading">
            <div class="formulas-burn__item" v-for="item in traits" :key="item.id">
                <FormulasTrait :item="item" :is-selected="isItemSelected(item)" :is-selection-mode="true"
                    :selected-count="selection.find(itm => itm.id == item.id)?.count" @select="selectTrait(item, $event)" />
            </div>
        </div>
        <div class="formulas-burn__no-items" v-if="traits.length < 1 && !isLoading">
            You don't have available traits
        </div>
        <div class="formulas-burn__loading" v-if="isLoading">
            <img class="loader" src="@/assets/img/spinner-clear.svg" width="100">
        </div>
    </div>
</template>
<script setup>
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, onBeforeUnmount, ref } from "vue-demi";
import FormulasTrait from "../FormulasTrait/FormulasTrait.vue";
import CollectionApeTrait from "@/components/Collection/CollectionApeTraits/CollectionApeTrait.vue";
import { useActionBar } from "@/components/AActionBar/useActionBar";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { estimateForgeAmount } from "@/helpers/estimate-forge-amount";
import { formatUtilities } from "@/helpers/format-utilities";
import { forgeBlocks } from "@/functions/formulas/forge-blocks";
import { ACHIEVEMENT_FLAG_ID } from "@/helpers/achievement-flag";
defineProps({ isLoading: Boolean })
const selection = ref([])
const walletCollectionStore = useWalletCollectionStore()
const traits = computed(() => {
    const { methMachines, teenResurrections, breedingReplenishments } = walletCollectionStore

    const formattedReplenishment = formatUtilities(breedingReplenishments)
    const formattedMachines = formatUtilities(methMachines)
    const formattedResurrections = formatUtilities(teenResurrections)

    console.log({
        methMachines, teenResurrections, breedingReplenishments,
        formattedReplenishment,
        formattedMachines,
        formattedResurrections
    })

    const traits = walletCollectionStore.apeTraits.filter(trait => {
        // exclude achievement flag
        // if (trait.id == ACHIEVEMENT_FLAG_ID) return false
        // exclude GAC formulas out of burn
        if (!!trait.team_type) return false
        return true
    })

    return [...traits, ...formattedReplenishment, ...formattedMachines, ...formattedResurrections]
})
const emit = defineEmits(['back'])
const selectTrait = (item, count) => {
    const idx = selection.value.findIndex((token) => token.id == item.id && token.rarity === item.rarity)
    if (idx < 0) {
        selection.value.push({ ...item, id: item.id, count })
    } else {
        if (count > 0) {
            const newArr = [...selection.value]
            newArr[idx].count = count
            selection.value = newArr
        } else {
            selection.value.splice(idx, 1)
        }
    }
}
const isItemSelected = (item) => {
    return selection.value.findIndex(itm => itm.id == item.id && item.rarity === itm.rarity) > -1
}
const totalTraitsCountSelected = computed(() => {
    return selection.value.reduce((sum, item) => sum + item.count, 0)
})
const estimatedForgeBlocks = computed(() => estimateForgeAmount(selection.value))
useActionBar().show({
    text: computed(() => {
        if (selection.value.length) {
            // return `Traits ${selection.value.reduce((str, item, index) => str += `#${item.id} x${item.count}${index !== selection.value.length - 1 ? ', ' : ''}`, '')} selected to be forge `
            return `You will receive ${estimatedForgeBlocks.value} Forge Block${totalTraitsCountSelected.value > 1 ? 's' : ''}`
        }
        return 'Select traits to forge'
    }),
    canApprove: computed(() => selection.value.length > 0),
    approveText: computed(() => 'Create Forge Block' + (estimatedForgeBlocks.value > 1 ? 's' : '')),
    approve: async () => {
        // approve
        useActionBar().isLoading.value = true

        const result = await forgeBlocks(selection.value)

        useActionBar().isLoading.value = false
        if (!result) return
        useModal().open({ name: ModalName.FormulasPrizeModal, props: { type: "forge_block", item: { count: result.blocksForged } } })
        walletCollectionStore.fetchApeTraits()
        walletCollectionStore.fetchForgeBlocks()
        emit('back')
    },

    cancel: () => {
        emit('back')
    }
})
onBeforeUnmount(() => {
    useActionBar().hide()
})
</script>
<style lang="scss">
.formulas-burn {
    &__title {
        text-transform: uppercase;
        text-align: center;
        font-size: 30px;
        margin-bottom: 20px;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -15px -10px;
    }

    &__item {
        width: calc(100% / 4 - 20px);
        margin: 15px 10px;
    }

    &__no-items {
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
        padding-top: 30px;
        opacity: 0.7;
    }

    &__loading {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 30px;
    }

    @media screen and (max-width: 992px) {
        &__title {
            font-size: 28px;
        }

        &__item {
            width: calc(100% / 3 - 20px);
        }
    }

    @media screen and (max-width: 700px) {
        &__title {
            font-size: 24px;
        }

        &__item {
            width: calc(100% / 2 - 20px);
        }
    }

    @media screen and (max-width: 500px) {
        &__title {
            font-size: 20px;
        }

        &__items {
            margin: -15px 0;
        }

        &__item {
            width: 100%;
            margin: 15px 0;
        }
    }
}
</style>
<template>
  <FormulasIngredientSelectable
    :items="items"
    :idx="idx"
    criteriaName="Teen Resurrection"
    :tooltipText="{
      content: 'Any Teen Resurrection',
      html: true,
      distance: 10,
    }"
    selectText="Select Teen Resurrection"
    selectEmptyText="You don't have Teen Resurrections"
    v-model:selectedItem="formulasStore.selectedTraits[idx]"
  >
    <template #selected-item="{ item }">
      <FormulasIngredientResurrectionItem
        :isSatisfy="isSatisfy"
        :isSelectMode="false"
        :item="item"
      />
      <!-- <ApeTrait class="formulas-ingredient-selectable__preview" :item="item">
                <template #details>
                    <button class="ape-trait__button formulas-ingredient-selectable__button">Change</button>
                </template>
            </ApeTrait>-->
    </template>
    <template #select-item="itemInfo">
      <!-- <FormulasIngredientUniversalTrait v-bind="itemInfo" /> -->
      <FormulasIngredientResurrectionItem
        :isSelectMode="true"
        v-bind="itemInfo"
      />
    </template>
  </FormulasIngredientSelectable>
</template>
<script setup>
import { computed } from "vue-demi";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import FormulasIngredientResurrectionItem from "./FormulasIngredientResurrectionItem.vue";
import FormulasIngredientSelectable from "../FormulasIngredientSelectable/FormulasIngredientSelectable.vue";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
const props = defineProps({
  item: {
    type: Object,
  },
  idx: Number,
  isSatisfy: Boolean,
});
const formulasStore = useFormulasStore();
const selectedTrait = computed(() => {
  return formulasStore.selectedTraits[props.idx];
});

const items = computed(() => {
  // const items = [];
  // useWalletCollectionStore().teenResurrections.forEach((item) => {
  //   if (items.findIndex((itm) => itm.guild === item.guild) < 0) {
  //     items.push(item);
  //   }
  // });

  const selectedTraits = Object.values(formulasStore.selectedTraits);
  const items = useWalletCollectionStore().teenResurrections.filter((trait) => {
    return (
      selectedTraits.filter((t) => t.id == trait.id).length <
      trait.offChainCount + trait.onChainCount
    );
  });

  return items;
});
</script>
<style lang="scss"></style>
